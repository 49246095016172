<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      Logo goes here
    </CSidebarBrand>
    <div style="margin: 10px">
      <CSelect
        aria-label="Выбор проекта"
        :value.sync="currentProjectId"
        :options="[
          { label: 'Гороскоп', value: 1 },
          { label: 'Тигр', value: 2 },
          { label: 'Квартира', value: 3 },
          { label: 'Большая денежная кнопка', value: 4 },
          { label: 'ЧГК', value: 5 },
          { label: 'Следуй за кроликом', value: 6 },
        ]"
        @change="changeActiveProject"
      >
      </CSelect>
    </div>
    <CSidebarNav>
      <CSidebarNavItem
        v-for="(navItem, index) in currentNavigation"
        :to="navItem.to"
        :name="navItem.name"
        :key="index"
        :icon="navItem.icon"
      />
    </CSidebarNav>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
export default {
  name: "TheSidebar",
  props: {
    projectId: Number,
  },
  data() {
    return {
      currentProjectId: null,
      currentNavigation: [],
      navigation: [
        {
          projectId: 1,
          name: "Тесты",
          to: "/quizes",
          icon: "",
        },
        {
          projectId: 1,
          name: "Предсказания",
          to: "/predictions",
          icon: "",
        },
        {
          projectId: 0,
          name: "FAQ",
          to: "/faqs",
          icon: "",
        },
        {
          projectId: 1,
          name: "Знаки зодиака",
          to: "/zodiac-signs",
          icon: "",
        },
        {
          projectId: 1,
          name: "Статистика",
          to: "/participants/stats",
          icon: "",
        },
        {
          projectId: 1,
          name: "Отчёт об ответах",
          to: "/reporting/total-answer-count",
          icon: "",
        },
        {
          projectId: 2,
          name: "Факты",
          to: "/tiger-facts",
          icon: "",
        },
        {
          projectId: 6,
          name: "Советы",
          to: "/rabbit-advices",
          icon: "",
        },
        {
          projectId: 0,
          name: "Тексты и картинки",
          to: "/static",
          icon: "",
        },
      ],
    };
  },
  created() {
    this.currentProjectId = this.projectId;
    this.getCurrentNavigation();
  },
  computed: {
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
  },
  methods: {
    changeActiveProject() {
      this.getCurrentNavigation();
    },
    getCurrentNavigation() {
      this.currentNavigation = [];
      for (const navItem of this.navigation) {
        console.log(navItem.projectId === this.p)
        if (navItem.projectId === this.currentProjectId || navItem.projectId === 0) {
          this.currentNavigation.push(navItem);
        }
      }
    },
  },
};
</script>
